<div fxLayoutAlign="center center">
  <app-filters-chimp
    [fields]="gridFilters"
    [filters]="filters"
    (removeFilter)="removeFilter.emit($event)"
  ></app-filters-chimp>
  <app-filters-chimp
    [fields]="clientSideGridFilters"
    [client_side_filters]="client_side_filters"
    (removeClientSideFilter)="removeClientSideFilter.emit($event)"
  ></app-filters-chimp>
  <button
    class="grid-button"
    mat-button
    (click)="openFilters.emit()"
    *ngIf="gridFilters?.length > 0"
  >
    <mat-icon>filter_alt</mat-icon>
    <span class="translations" *ngIf="!addFilterText"> {{ "common.grid.open-filter" | translate }} </span>
    <span class="translations" *ngIf="addFilterText"> {{ addFilterText }}  </span>
  </button>
  <button
    class="grid-button"
    mat-button
    (click)="openClientSideFilters.emit()"
    *ngIf="clientSideGridFilters?.length > 0"
  >
    <mat-icon>filter_alt</mat-icon>
    <span class="translations"> {{ "common.grid.open-filter" | translate }} </span>
  </button>
  <button
    class="grid-button"
    mat-button
    (click)="refreshPage.emit()" *ngIf="!Refresh"
  >
    <mat-icon>refresh</mat-icon>
    <span class="translations"> {{ "common.grid.refresh" | translate }} </span>
  </button>
  <button class="grid-button" mat-button (click)="addItem.emit()" *ngIf="canAdd">
    <mat-icon>add</mat-icon>
    <span class="translations"> {{ addText }} </span>
  </button>
  <button
    class="grid-button"
    mat-button
    (click)="Tracking.emit()" *ngIf="canView"
  >
    <mat-icon>add_location</mat-icon>
    <span class="translations"> {{ "common.grid.open-tracking" | translate }} </span>
  </button>
  <button
    class="grid-button"
    mat-button
    (click)="downloadQRcodes.emit()" *ngIf="models"
  >
    <mat-icon>cloud_download</mat-icon>
    <span class="translations"> {{ "common.qrcode.download" | translate }} </span>
  </button>
  <button
    class="grid-button"
    mat-button
    (click)="openSuspendPrint.emit()" *ngIf="suspend_print"
  >
    <mat-icon svgIcon="suspend-print" aria-hidden="false" aria-label="Suspend print icon"></mat-icon>
    <span class="translations"> {{ "common.grid.suspend-print" | translate }} </span>
  </button>
  <button
    class="grid-button"
    mat-button
    (click)="openSuspendPdfPrint.emit()" *ngIf="suspend_print"
  >
    <mat-icon svgIcon="suspend-print" aria-hidden="false" aria-label="Suspend print icon"></mat-icon>
    <span class="translations"> {{ "common.grid.suspend-print" | translate }} </span>
  </button>
  <button
    class="grid-button"
    mat-button
    (click)="openResumePrint.emit()" *ngIf="delivery_codes"
  >
    <mat-icon>print</mat-icon>
    <span class="translations">{{ "common.grid.resume-print" | translate }} </span>
  </button>
  <button
    class="grid-button"
    mat-button
    (click)="travelToJoin.emit()" *ngIf="smartBin"
  >
    <mat-icon><i class="fa fa-link" aria-hidden="true"></i>
    </mat-icon>
    <span class="translations">{{ "common.grid.travel-to-join" | translate }} </span>
  </button>
</div>
