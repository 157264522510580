<h2 mat-subheader>{{ item?.label }}</h2>

<mat-list-item
  *ngFor="let child of item?.items"
  routerLinkActive="active"
  [routerLink]="child.link"
  [routerLinkActiveOptions]="{ exact: true }"
>
  <a matLine class="menu-link" *ngIf="!child.sub_menu">{{ child.label }}</a>
  <!--<a matLine class="menu-link" *ngIf="child.sub_menu">{{ child.label }}</a>-->
  <mat-list-item
    *ngFor="let sub of child.sub_menu?.items"
    routerLinkActive="active"
    [routerLink]="sub.link"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    <a matLine class="menu-link">{{sub.label }}</a>
  </mat-list-item>
</mat-list-item>
