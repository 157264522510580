import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { map, tap } from 'rxjs';
import { Models, Modules, Permission } from 'src/app/data/model/permission';

@Component({
  selector: 'app-navigation-menu',
  templateUrl: './navigation-menu.component.html',
  styleUrls: ['./navigation-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationMenuComponent implements OnInit, OnChanges {
  public menu: Map<string, Menu[]> = new Map();

  currentMenu: Menu[];

  @Input() viewPermissions: Permission[];

  constructor(private translate: TranslateService) {
    this.menu.set('it', it);
  }
  ngOnChanges(): void {
    this.currentMenu = this.authorizedMenu(
      this.menu.get(this.translate.currentLang)
    );
  }

  ngOnInit(): void {
    this.currentMenu = this.authorizedMenu(
      this.menu.get(this.translate.currentLang)
    );

    this.translate.onLangChange.pipe(
      map((langEvent) => this.menu.get(langEvent.lang)),
      map((menu) => this.authorizedMenu(menu)),
      tap((menu) => (this.currentMenu = menu))
    );
  }

  authorizedMenu(menu: Menu[]): Menu[] {
    return menu
      .map((menu) => this.menuWithoutUnauthorizedChild(menu))
      .filter((menu) => menu.items.length > 0);
  }

  menuWithoutUnauthorizedChild(menu: Menu): Menu {
    return {
      ...menu,
      items: menu.items
        .map((child) => this.filterAndAuthorizeSubMenu(child))
        .filter((child) => this.filterChild(child) && (child.sub_menu?.items.length || !child.sub_menu)),
    };
  }
    /*return {
      ...menu,
      items: menu.items.filter((child) => this.filterChild(child)),
    };
  }*/

  filterAndAuthorizeSubMenu(child: MenuChild): MenuChild {
    if (child.sub_menu) {
      return {
        ...child,
        sub_menu: {
          ...child.sub_menu,
          items: child.sub_menu.items
            .map((subChild) => this.filterAndAuthorizeSubMenu(subChild))
            .filter((subChild) => this.filterChild(subChild)),
        },
      };
    }
    return child;
  }

  filterChild(child: MenuChild): boolean {
    return (
      !child.authorizationModel ||
      this.viewPermissions?.some(
        (pemrission) =>
          pemrission.model === child.authorizationModel.model &&
          pemrission.module === child.authorizationModel.module

      )
    );
  }
}

export type Menu = {
  label: string;
  items: MenuChild[];
};
export type SubMenu = {
  label: string;
  items: MenuChild[];
};
export type MenuChild = {
  label: string;
  link?: string[];
  sub_menu?: SubMenu;
  authorizationModel?: {
    model: Models;
    module: Modules;
  };
};

const it: Menu[] = [
  {
    label: 'Smart Bin',
    items: [
      {
        label: 'Tessere Smart Bin',
        link: ['/card-smart-bin'],
        authorizationModel: {
          module: Modules.DATA_COLLECTOR,
          model: Models.SMARTBIN,
        },
      },
      {
        label: 'Smart Bin',
        link: ['/smart-bin'],
        authorizationModel: {
          module: Modules.DATA_COLLECTOR,
          model: Models.SMARTBIN,
        },
      }
    ],
  },
  {
    label: 'Mappe',
    items: [
      {
        label: 'Mappa Cestini',
        link: ['/tag-map'],
        authorizationModel: {
          module: Modules.TAG_APP_API,
          model: Models.TAG_EVENT,
        },
      },
      {
        label: 'Mappa Automezzi',
        link: ['/map'],
        authorizationModel: {
          module: Modules.TELEMETRY,
          model: Models.GPSTRACK
        }
      }
    ],
  },
  {
    label: 'Ecologia e Servizi',
    items: [
      {
        label: 'Centri di Raccolta',
        link: ['/eco-point'],
        authorizationModel: {
          module: Modules.DATA_COLLECTOR,
          model: Models.ECOPOINT,
        },
      },
      {
        label: 'Operatori',
        link: ['/operators'],
        authorizationModel: {
          module: Modules.DATA_COLLECTOR,
          model: Models.OPERATOR,
        },
      },
      {
        label: 'Chioschi',
        link: ['/kiosks'],
        authorizationModel: {
          module: Modules.DATA_COLLECTOR,
          model: Models.KIOSK,
        },
      },
      {
        label: 'Registro Utenze',
        sub_menu:{
          label: 'Registro Utenze',
          items: [
            {
              label: 'Contratti',
              link: ['/customer_contract'],
              authorizationModel: {
                module: Modules.REGISTRY,
                model: Models.CONTRACT,
              },
            },
            {
              label: 'Tessere',
              link: ['/cards-management'],
              authorizationModel: {
                module: Modules.AUTH, 
                model: Models.PORTAL_USER,
              },
            },
          ],
        }
      }
    ],
  },
  {
    label: 'Centri Del Riuso',
    items: [
      {
        label: 'Centri Del Riuso',
        link: ['/cdri'],
        authorizationModel: {
          module: Modules.DATA_COLLECTOR,
          model: Models.ECOPOINT,
        },
      },
      {
        label: 'Registro di Carico',
        link: ['/delivery'],
        authorizationModel: {
          module: Modules.REUSECENTER,
          model: Models.DELIVERY,
        },
      },
      {
        label: 'Registro di Scarico',
        link: ['/pickup'],
        authorizationModel: {
          module: Modules.REUSECENTER,
          model: Models.DELIVERY,
        },
      },
      {
        label: 'Codici da Stampare',
        link: ['/delivery-codes'],
        authorizationModel: {
          module: Modules.REUSECENTER,
          model: Models.DELCODE,
        },
      },
      {
        label: 'Documenti',
        link: ['/reuse-docs'],
        authorizationModel: {
          module: Modules.REUSECENTER,
          model: Models.DELDOCUMENTS,
        },
      },
      {
        label: 'Registro Beni In Uscita a Fine Giacenza',
        link: ['/delivery-over-time'],
        authorizationModel: {
          module: Modules.REUSECENTER,
          model: Models.DELIVERY,
        },
      },
    ],
  },
  {
    label: 'Report',
    items: [
      {
        label: 'Report Operatori',
        link:  ['/drivers-job-report'],
        authorizationModel: {
          module: Modules.MODA,
          model: Models.DRIVER,
        },
      },
      {
        label: 'Grafana',
        link:  ['/grafana-reports'],
        authorizationModel: {
          module: Modules.REPORT,
          model: Models.GRAFANA,
        },
      },
      {
        label: 'Conferimenti',
        link:  ['/conferrals'],
        authorizationModel: {
          module: Modules.CONFERRALS,
          model: Models.CONFERRAL,
        },
      },
      {
        label: 'Utenze Sovrasoglia',
        link:  ['/conferral-over-limits'],
        authorizationModel: {
          module: Modules.CONFERRALS,
          model: Models.CONFERRAL,
        },
      },
      {
        label: 'Report Spazzatrici',
        link:  ['/sweeper-reports'],
        authorizationModel: {
          module: Modules.TELEMETRY,
          model: Models.GPSTRACK,
        },
      },
      {
        label: 'Report Attività Mezzi',
        link:  ['/customreports'],
        authorizationModel: {
          module: Modules.REPORT,
          model: Models.CUSTOMREPORTS,
        },
      },
      {
        label: 'Report Soste-Pause Mezzi',
        link:  ['/customreports2'],
        authorizationModel: {
          module: Modules.REPORT,
          model: Models.CUSTOMREPORTS,
        },
      },
    ]
  },
  {
    label: 'Gestione Dispositivi',
    items: [
      {
        label: 'Device collector',
        link: ['/device-collector'],
        authorizationModel: {
          module: Modules.DATA_COLLECTOR,
          model: Models.DEVICE_COLLECTOR,
        },
      },
      {
        label: 'Dispositivi',
        link: ['/device'],
        authorizationModel: {
          module: Modules.DATA_COLLECTOR,
          model: Models.DEVICE,
        },
      }
    ],
  },
  {
    label: 'Gestione Automezzi',
    items: [
      {
        label: 'Ordini di servizio',
        link: ['/service-order'],
        authorizationModel: {
          module: Modules.DATA_COLLECTOR,
          model: Models.ORDINIS,
        },
      },
      {
        label: 'Veicoli',
        link: ['/vehicle'],
        authorizationModel: {
          module: Modules.DATA_COLLECTOR,
          model: Models.VEHICLE,
        },
      },
      {
        label: 'Codici QR',
        link: ['/qr-codes'],
        authorizationModel: {
          module: Modules.MOBILE,
          model: Models.VEHICLEQRCODE,
        },
      },
      {
        label: 'Whitelist',
        link: ['/whitelist'],
        authorizationModel: {
          module: Modules.BIN_COLLECTION,
          model: Models.WHITELIST,
        },
      },
      {
        label: 'Telemetrie',
        sub_menu:{
          label: 'Telemetrie',
          items: [
            {
              label: 'Elenco telemetrie',
              link: ['/telemetries'],
              authorizationModel: {
                module: Modules.TELEMETRY,
                model: Models.TELEMETRY,
              },
            },
            {
              label: 'Anomalie',
              link: ['/device-event'],
              authorizationModel: {
                module: Modules.TELEMETRY,
                model: Models.DEVICEEVENT,
              },
            },
            {
              label: 'Telaio',
              link: ['/chassis-state'],
              authorizationModel: {
                module: Modules.TELEMETRY,
                model: Models.CHASSIS,
              },
            }
          ],
        }
      }/*,
      {
        label: 'Servizi Tag',
        sub_menu:{
          label: 'Servizi Tag',
          items: [
            {
              label: 'Whitelist',
              link: ['/whitelist'],
              authorizationModel: {
                module: Modules.BIN_COLLECTION,
                model: Models.WHITELIST,
              },
            }
          ],
        }
      }*/
    ],
  },
  {
    label: 'Gestione Portale',
    items: [
      {
        label: 'Utenti',
        link: ['/user'],
        authorizationModel: {
          module: Modules.AUTH,
          model: Models.PORTAL_USER,
        },
      },
      {
        label: 'Gruppi',
        link: ['/group'],
        authorizationModel: {
          model: Models.PORTAL_GROUP,
          module: Modules.MANAGEMENT,
        },
      },
      {
        label: 'Gruppi di permessi',
        link: ['/permission-groups'],
        authorizationModel: {
          model: Models.USER_PORTAL_GROUP,
          module: Modules.AUTH,
        },
      },
      {
        label: 'Servizi registrati',
        link: ['/app-management'],
        authorizationModel: {
          model: Models.APPMANAGEMENT,
          module: Modules.SERVICE,
        },
      },
    ],
  },
];
