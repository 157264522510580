<h1 mat-dialog-title>
  <span>{{ "common.modal.filters.title" | translate }}</span>
</h1>
<div mat-dialog-content>
  <form
    [formGroup]="filterFormGroup"
    fxLayout="row"
    aria-describedby="portal group table"
  >
    <!-- Field form field -->
    <mat-form-field>
      <mat-label>{{ "common.modal.filters.field" | translate }}</mat-label>
      <mat-select formControlName="field">
        <mat-option *ngFor="let f of data.fields" [value]="f">
          {{ f.text }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="filterFormGroup.get('field').hasError('required')">{{
        "common.dialog.errors.required" | translate
      }}</mat-error>
    </mat-form-field>
    <!-- Value form field -->
    <ng-container [ngSwitch]="selectedField?.type">
      <ng-container *ngSwitchCase="FieldType.TEXT">
        <mat-form-field fxFlex>
          <mat-label>{{ "common.modal.filters.value" | translate }}</mat-label>
          <input matInput formControlName="value" />
          <mat-error
            *ngIf="filterFormGroup.get('value').hasError('required')"
            >{{ "common.dialog.errors.required" | translate }}</mat-error
          >
        </mat-form-field>
      </ng-container>
      <!--UPPERCASE-->
      <ng-container *ngSwitchCase="FieldType.UPPERCASE">
        <mat-form-field fxFlex>
          <mat-label>{{ "common.modal.filters.value" | translate }}</mat-label>
          <input matInput formControlName="value" oninput="this.value = this.value.toUpperCase()" />
          <mat-error
            *ngIf="filterFormGroup.get('value').hasError('required')"
            >{{ "common.dialog.errors.required" | translate }}</mat-error>
        </mat-form-field>
      </ng-container>
      <!--LOWERCASE_ARRAY-->
      <ng-container *ngSwitchCase="FieldType.LOwERCASE_ARRAY">
        <mat-form-field fxFlex>
          <mat-label>{{ "common.modal.filters.value" | translate }}</mat-label>
          <mat-chip-list #chipList>
            <mat-chip
              *ngFor="let value of values"
              [selectable]="selectable"
              [removable]="removable"
              (removed)="remove(value)">
              {{ value }}
              <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
            </mat-chip>
            <input
              #valueInput
              formControlName="value"
              [matChipInputAddOnBlur]="addOnBlur"
              (matChipInputTokenEnd)="add($any($event))"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              [matChipInputFor]="chipList"
              [matAutocomplete]="filterAutocomplete"
            />
          </mat-chip-list>
          <mat-autocomplete
            (optionSelected)="selected($any($event))"
            #filterAutocomplete="matAutocomplete"
            [displayWith]="displayFn()"
          >
            <mat-option
              *ngFor="let option of filteredOptions | async"
              [value]="option.id"
            >
              {{ option.description }}
            </mat-option>
          </mat-autocomplete>

          <mat-error
            *ngIf="filterFormGroup.get('value').hasError('required')"
            >{{ "common.dialog.errors.required" | translate }}</mat-error
          >
        </mat-form-field>
      </ng-container>
      <!--UPPERCASE_ARRAY-->
      <ng-container *ngSwitchCase="FieldType.UPPERCASE_ARRAY">
        <mat-form-field fxFlex>
          <mat-label>{{ "common.modal.filters.value" | translate }}</mat-label>
          <mat-chip-list #chipList>
            <mat-chip
              *ngFor="let value of values"
              [selectable]="selectable"
              [removable]="removable"
              (removed)="remove(value)">
              {{ value }}
              <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
            </mat-chip>
            <input
              #valueInput
              formControlName="value"
              [matChipInputAddOnBlur]="addOnBlur"
              (matChipInputTokenEnd)="add($any($event))"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              [matChipInputFor]="chipList"
              [matAutocomplete]="filterAutocomplete"
              oninput="this.value = this.value.toUpperCase()"
            />
          </mat-chip-list>
          <mat-autocomplete
            (optionSelected)="selected($any($event))"
            #filterAutocomplete="matAutocomplete"
            [displayWith]="displayFn()"
          >
            <mat-option
              *ngFor="let option of filteredOptions | async"
              [value]="option.id"
            >
              {{ option.description }}
            </mat-option>
          </mat-autocomplete>

          <mat-error
            *ngIf="filterFormGroup.get('value').hasError('required')"
            >{{ "common.dialog.errors.required" | translate }}</mat-error
          >
        </mat-form-field>
      </ng-container>
      <!--GRAFANA_AUTOCOMPLETE-->
      <ng-container *ngSwitchCase="FieldType.GRAFANA_AUTOCOMPLETE">
        <mat-form-field fxFlex>
          <mat-label>{{ "common.modal.filters.value" | translate }}</mat-label>
          <input
            matInput
            formControlName="value"
            [matAutocomplete]="filterAutocomplete"
          />

          <mat-autocomplete
            #filterAutocomplete="matAutocomplete"
            [displayWith]="displayFn()"
          >
            <mat-option
              *ngFor="let option of filteredOptions | async"
              [value]="option.description"
            >
              {{ option.description }}
            </mat-option>
          </mat-autocomplete>

          <mat-error
            *ngIf="filterFormGroup.get('value').hasError('required')"
            >{{ "common.dialog.errors.required" | translate }}</mat-error
          >
        </mat-form-field>
      </ng-container>
      <!--VEHICLE_STATUS-->
      <ng-container *ngSwitchCase="FieldType.VEHICLE_STATUS">
        <mat-form-field fxFlex>
          <mat-label>{{ "common.modal.filters.value" | translate }}</mat-label>
          <mat-select formControlName="value" multiple [(value)]="value">
            <mat-option value="Attivo">Attivo</mat-option>
            <mat-option value="Inattivo">Inattivo</mat-option> 
            <mat-option value="Manutenzione">Manutenzione</mat-option>
          </mat-select>
          <mat-error *ngIf="filterFormGroup.get('value').hasError('required')">{{ "common.dialog.errors.required" | translate }}</mat-error>
        </mat-form-field>
      </ng-container>
       <!--DOCTYPE-->
       <ng-container *ngSwitchCase="FieldType.DOCTYPE">
        <mat-form-field fxFlex>
          <mat-label>{{ "common.modal.filters.value" | translate }}</mat-label>
          <mat-select formControlName="value" [(value)]="value">
            <mat-option value="1">Documento di Carico</mat-option>
            <mat-option value="2">Documento di Scarico</mat-option> 
          </mat-select>
          <mat-error *ngIf="filterFormGroup.get('value').hasError('required')">{{ "common.dialog.errors.required" | translate }}</mat-error>
        </mat-form-field>
      </ng-container>
      <!--WHITELIST_TYPE-->
      <ng-container *ngSwitchCase="FieldType.WHITELIST_TYPE">
        <mat-form-field fxFlex>
          <mat-label>{{ "common.modal.filters.value" | translate }}</mat-label>
          <mat-select formControlName="value" [(value)]="value">
            <mat-option value="1">WHITELIST</mat-option>
            <mat-option value="2">BLACKLIST</mat-option>
            <mat-option value="3">SERIAL LIST</mat-option> 
          </mat-select>
          <mat-error *ngIf="filterFormGroup.get('value').hasError('required')">{{ "common.dialog.errors.required" | translate }}</mat-error>
        </mat-form-field>
      </ng-container>
      <!--WLTAG_LIST_MODE-->
      <ng-container *ngSwitchCase="FieldType.WLTAG_LIST_MODE">
        <mat-form-field fxFlex>
          <mat-label>{{ "common.modal.filters.value" | translate }}</mat-label>
          <mat-select formControlName="value" [(value)]="value">
            <mat-option value="0">DEACTIVATED</mat-option>
            <mat-option value="1">WHITELIST</mat-option>
            <mat-option value="2">BLACKLIST</mat-option>
          </mat-select>
          <mat-error *ngIf="filterFormGroup.get('value').hasError('required')">{{ "common.dialog.errors.required" | translate }}</mat-error>
        </mat-form-field>
      </ng-container>
      <!--STATUS-->
      <ng-container *ngSwitchCase="FieldType.STATUS">
        <mat-form-field fxFlex>
          <mat-label>{{ "common.modal.filters.value" | translate }}</mat-label>
          <mat-select formControlName="value" multiple [(value)]="value">
            <mat-option value="In attesa">In attesa</mat-option>
            <mat-option value="Preso in carico">Preso in carico</mat-option> 
            <mat-option value="Completato">Completato</mat-option>
            <mat-option value="Completato Parzialmente">Completato Parzialmente</mat-option>
            <mat-option value="Sospeso">Sospeso</mat-option>
            <mat-option value="Ignorato">Ignorato</mat-option>
            <mat-option value="Chiuso da sistema">Chiuso da sistema</mat-option>
          </mat-select>
          <mat-error *ngIf="filterFormGroup.get('value').hasError('required')">{{ "common.dialog.errors.required" | translate }}</mat-error>
        </mat-form-field>
      </ng-container>
      <!--CONTRACT_TYPE-->
      <ng-container *ngSwitchCase="FieldType.CONTRACT_TYPE">
        <mat-form-field fxFlex>
          <mat-label>{{ "common.modal.filters.value" | translate }}</mat-label>
          <mat-select formControlName="value" multiple [(value)]="value">
            <mat-option value="0">DOMESTICA</mat-option>
            <mat-option value="1">NON DOMESTICA</mat-option> 
          </mat-select>
          <mat-error *ngIf="filterFormGroup.get('value').hasError('required')">{{ "common.dialog.errors.required" | translate }}</mat-error>
        </mat-form-field>
      </ng-container>
      <!--BOOLEAN-->
      <ng-container *ngSwitchCase="FieldType.BOOLEAN">
        <mat-form-field fxFlex>
          <mat-label>{{ "common.modal.filters.value" | translate }}</mat-label>
          <mat-select formControlName="value">
            <mat-option value="True">
              <mat-icon>
                check
              </mat-icon>
            </mat-option>
            <mat-option value="False">
              <mat-icon>
                clear
              </mat-icon>
            </mat-option>
          </mat-select>
          <mat-error *ngIf="filterFormGroup.get('value').hasError('required')">{{ "common.dialog.errors.required" | translate }}</mat-error>
        </mat-form-field>
      </ng-container>
      <!--CLIENT-SIDE-WASTE-TYPE-->
      <ng-container *ngSwitchCase="FieldType.WASTE_TYPE">
        <mat-form-field fxFlex>
          <mat-label>{{ "common.modal.filters.value" | translate }}</mat-label>
          <mat-select formControlName="value" multiple [(value)]="value">
            <mat-option value="SECCO">Secco</mat-option>
            <mat-option value="UMIDO">Umido</mat-option>
            <mat-option value="PLASTICA">Plastica</mat-option>
            <mat-option value="VERDE">Verde</mat-option>
            <mat-option value="VETRO">Vetro</mat-option>
            <mat-option value="CARTA">Carta</mat-option> 
          </mat-select>
          <mat-error *ngIf="filterFormGroup.get('value').hasError('required')">{{ "common.dialog.errors.required" | translate }}</mat-error>
        </mat-form-field>
      </ng-container>
      <!--CLIENT-SIDE-LOCATION-->
      <ng-container *ngSwitchCase="FieldType.LOCATION">
        <mat-form-field fxFlex>
          <mat-label>{{ "common.modal.filters.value" | translate }}</mat-label>
          <mat-select formControlName="value" multiple [(value)]="value">
            <mat-option value="ARBA">ARBA</mat-option>
            <mat-option value="AZZANO DECIMO">AZZANO DECIMO</mat-option>
            <mat-option value="BRUGNERA">BRUGNERA</mat-option>
            <mat-option value="CASARSA DELLA DELIZIA">CASARSA DELLA DELIZIA</mat-option>
            <mat-option value="CASTELNUOVO DEL FRIULI">CASTELNUOVO DEL FRIULI</mat-option>
            <mat-option value="CAVASSO NUOVO">CAVASSO NUOVO</mat-option> 
            <mat-option value="CHIONS">CHIONS</mat-option>
            <mat-option value="CORDOVADO">CORDOVADO</mat-option>
            <mat-option value="FIUME VENETO">FIUME VENETO</mat-option>
            <mat-option value="FONTANAFREDDA">FONTANAFREDDA</mat-option>
            <mat-option value="MORSANO AL TAGLIAMENTO">MORSANO AL TAGLIAMENTO</mat-option>
            <mat-option value="PASIANO DI PORDENONE">PASIANO DI PORDENONE</mat-option> 
            <mat-option value="PINZANO AL TAGLIAMENTO">PINZANO AL TAGLIAMENTO</mat-option>
            <mat-option value="POLCENIGO">POLCENIGO</mat-option>
            <mat-option value="PORCIA">PORCIA</mat-option>
            <mat-option value="PRAVISDOMINI">PRAVISDOMINI</mat-option>
            <mat-option value="SACILE">SACILE</mat-option>
            <mat-option value="SAN GIORGIO DELLA RICHINVELDA">SAN GIORGIO DELLA RICHINVELDA</mat-option> 
            <mat-option value="SAN MARTINO AL TAGLIAMENTO">SAN MARTINO AL TAGLIAMENTO</mat-option>
            <mat-option value="SAN VITO AL TAGLIAMENTO">SAN VITO AL TAGLIAMENTO</mat-option>
            <mat-option value="SESTO AL REGHENA">SESTO AL REGHENA</mat-option>
            <mat-option value="SPILIMBERGO">SPILIMBERGO</mat-option>
            <mat-option value="TRAVESIO">TRAVESIO</mat-option>
            <mat-option value="VAJONT">VAJONT</mat-option> 
            <mat-option value="VALVASONE ARZENE">VALVASONE ARZENE</mat-option>
            <mat-option value="ZOPPOLA">ZOPPOLA</mat-option>
          </mat-select>
          <mat-error *ngIf="filterFormGroup.get('value').hasError('required')">{{ "common.dialog.errors.required" | translate }}</mat-error>
        </mat-form-field>
      </ng-container>
      <!--CLIENT_SIDE_BOOLEAN-->
      <ng-container *ngSwitchCase="FieldType.CLIENT_SIDE_BOOLEAN">
        <mat-form-field fxFlex>
          <mat-label>{{ "common.modal.filters.value" | translate }}</mat-label>
          <mat-select formControlName="value">
            <mat-option value="1">
              <p style="color:#2ec913">⬤ Connesso</p>
            </mat-option>
            <mat-option value="2">
              <p style="color:orange">⬤ Non Connesso da meno di 48H</p>
            </mat-option>
            <mat-option value="3">
              <p style="color:red">⬤ Non Connesso da più di 48H</p>
            </mat-option>
            <mat-option value="0">
              <p style="color:grey">⬤ Non MQuadro</p>
            </mat-option>
          </mat-select>
          <mat-error *ngIf="filterFormGroup.get('value').hasError('required')">{{ "common.dialog.errors.required" | translate }}</mat-error>
        </mat-form-field>
      </ng-container>
      <!--AUTOCOMPLETE-->
      <ng-container *ngSwitchCase="FieldType.AUTOCOMPLETE">
        <mat-form-field fxFlex>
          <mat-label>{{ "common.modal.filters.value" | translate }}</mat-label>
          <input
            matInput
            formControlName="value"
            [matAutocomplete]="filterAutocomplete"
          />

          <mat-autocomplete
            #filterAutocomplete="matAutocomplete"
            [displayWith]="displayFn()"
          >
            <mat-option
              *ngFor="let option of filteredOptions | async"
              [value]="option.id"
            >
              {{ option.description }}
            </mat-option>
          </mat-autocomplete>

          <mat-error
            *ngIf="filterFormGroup.get('value').hasError('required')"
            >{{ "common.dialog.errors.required" | translate }}</mat-error
          >
        </mat-form-field>
      </ng-container>
      <!--AUTOCOMPLETE_UPPER-->
      <ng-container *ngSwitchCase="FieldType.AUTOCOMPLETE_UPPER">
        <mat-form-field fxFlex>
          <mat-label>{{ "common.modal.filters.value" | translate }}</mat-label>
          <input
            matInput
            formControlName="value"
            [matAutocomplete]="filterAutocomplete"
            oninput="this.value = this.value.toUpperCase()"
          />

          <mat-autocomplete
            #filterAutocomplete="matAutocomplete"
            [displayWith]="displayFn()"
          >
            <mat-option
              *ngFor="let option of filteredOptions | async"
              [value]="option.id"
            >
              {{ option.description }}
            </mat-option>
          </mat-autocomplete>

          <mat-error
            *ngIf="filterFormGroup.get('value').hasError('required')"
            >{{ "common.dialog.errors.required" | translate }}</mat-error
          >
        </mat-form-field>
      </ng-container>
      <!--AUTOCOMPLETE_DATE-->
      <ng-container *ngSwitchCase="FieldType.AUTOCOMPLETE_DATE">
        <mat-form-field fxFlex>
          <mat-label>GG-MM-AAAA HH:MM:SS</mat-label>
          <input
            matInput
            formControlName="value"
            [matAutocomplete]="filterAutocomplete"
          />

          <mat-autocomplete
            #filterAutocomplete="matAutocomplete"
            [displayWith]="displayFn()"
          >
            <mat-option
              *ngFor="let option of filteredOptions | async"
              [value]="option.id"
            >
              {{ option.description }}
            </mat-option>
          </mat-autocomplete>

          <mat-error
            *ngIf="filterFormGroup.get('value').hasError('required')"
            >{{ "common.dialog.errors.required" | translate }}</mat-error
          >
        </mat-form-field>
      </ng-container>
      <!--CDRI SUB CATEGORY-->
      <ng-container *ngIf="this.filterFormGroup.value.value && check_if_json()">
        <mat-form-field fxFlex>
          <mat-label >{{ "common.modal.filters.value" | translate }}</mat-label>
          <mat-select [(value)]="type" multiple formControlName="type">
            <mat-option *ngFor="let subcategory of get_subcategory(this.filterFormGroup.value.value)" [value]="subcategory.cod">
              {{ subcategory.tipologia }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </ng-container>
      <!--DATETIME-->
      <ng-container *ngSwitchCase="FieldType.DATETIME">
        <mat-form-field fxFlex>
          <mat-label>{{ "common.modal.filters.value" | translate }}</mat-label>
          <input matInput type="date" formControlName="value">
        </mat-form-field>
      </ng-container>
      <!--DATERANGE-->
      <ng-container *ngSwitchCase="FieldType.DATERANGE">
        <mat-form-field appearance="fill">
          <mat-label>{{ "common.modal.filters.value" | translate }}</mat-label>
          <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
            <input matStartDate formControlName="start" placeholder="Inizio" [value]="start | date: 'yyyy-MM-dd'">
            <input matEndDate formControlName="end" placeholder="Fine" [value]="end | date: 'yyyy-MM-dd'">
          </mat-date-range-input>
          <mat-hint>DD/MM/YYYY – DD/MM/YYYY</mat-hint>
          <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </ng-container>
      <!--ADDRESS-->
      <div *ngSwitchCase="FieldType.ADDRESS" [fxLayout]="layoutDirection" fxLayoutAlign="start stretch">
        <ng-container *ngSwitchCase="FieldType.ADDRESS">
          <mat-form-field appearance="fill" [formGroup]="addressFormGroup" >
            <mat-label>Città/Comune</mat-label>
            <input matInput formControlName="city" placeholder="Città">
            <mat-error
              *ngIf="addressFormGroup.get('city').hasError('required')"
              >{{ "common.dialog.errors.required" | translate }}</mat-error
            >
          </mat-form-field>
          <mat-form-field appearance="fill" [formGroup]="addressFormGroup">
            <mat-label>Via</mat-label>
            <input matInput formControlName="street" placeholder="Via">
          </mat-form-field>
          <mat-form-field appearance="fill" [formGroup]="addressFormGroup">
            <mat-label>Civico</mat-label>
            <input  matInput formControlName="house_number" placeholder="Civico">
            <div *ngIf="addressFormGroup.hasError('streetWithHouseNumberError')">
              <small class="error" style="color: red;">
                {{ addressFormGroup.getError('streetWithHouseNumberError') }}
              </small>
            </div>
          </mat-form-field>
        </ng-container>
      </div>
      <!--GRAFANA_DATERANGE-->
      <ng-container *ngSwitchCase="FieldType.GRAFANA_DATERANGE">
        <mat-form-field appearance="fill">
          <mat-label>{{ "common.modal.filters.value" | translate }}</mat-label>
          <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
            <input matStartDate formControlName="start" placeholder="Inizio" [value]="start | date: 'yyyy-MM-dd'">
            <input matEndDate formControlName="end" placeholder="Fine" [value]="end | date: 'yyyy-MM-dd'">
          </mat-date-range-input>
          <mat-hint>DD/MM/YYYY – DD/MM/YYYY</mat-hint>
          <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </ng-container>
      <!--DEFAULT-->
      <ng-container *ngSwitchDefault>
        <mat-form-field fxFlex>
          <mat-label>{{ "common.modal.filters.value" | translate }}</mat-label>
          <input matInput disabled />
          <mat-error
            *ngIf="filterFormGroup.get('value').hasError('required')"
            >{{ "common.dialog.errors.required" | translate }}</mat-error
          >
        </mat-form-field>
      </ng-container>
    </ng-container>
  </form>
</div>
<div mat-dialog-actions fxLayoutAlign="center center">
  <button mat-button mat-dialog-close>
    {{ "common.dialog.cancel" | translate }}
  </button>
  <button mat-button cdkFocusInitial color="primary" (click)="addFilter()" >
    {{ "common.dialog.add" | translate }}
  </button>
</div>
