import { Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppActions } from 'src/app/store/app-store';
import { Menu } from '../../navigation-menu.component';

@Component({
  selector: 'app-navigation-list-item',
  templateUrl: './navigation-list-item.component.html',
  styleUrls: ['./navigation-list-item.component.scss'],
})
export class NavigationListItemComponent implements OnInit {
  @Input() item: Menu;

  constructor(private router: Router, private store: Store<any>) {}

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.hideMenu();
      }
    });
  }

  hideMenu() {
    this.store.dispatch(AppActions.hideNav());
  }
}
